
.chat-popup * {
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-color);
  z-index: 1000;
}

.chat-popup ul {
  list-style-type: none;
}

.chat-popup button {
  border: none;
  background-color: unset;
  cursor: pointer;
}

.container,
.App_open_popup {
  position: fixed;
  top: 20px; /* To change position of the widget */
  right: 20px;
}

.App_open_popup {
  width: 75px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.App_open_popup.closed {
  width: 0px;
  height: 0px;
}

.container {
  height: 500px;
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  background-color: var(--primary-color);
  transition: all 0.3s ease;
}

.container.closed {
  width: 0px;
  height: 0px;
}

.App_close_popup {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 10001;
}

.burger {
  position: absolute;
  top: 50%;
  fill: var(--accent-color);
  cursor: pointer;
  z-index: 999999;
  transition: all 0.3s ease;
}

.burger.right {
  left: 10px;
}

.burger.left {
  transform: rotate(180deg);
  right: 0;
}

.sidebar {
  z-index: 10000;
  position: absolute;
  width: 300px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  transition: all 0.3s ease;
  height: 100%;
}

.sidebar.closed {
  width: 0;
  padding: 0;
}

.sidebar.closed > * {
  display: none;
}

.sidebar.closed .burger {
  display: block;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 0.3rem;
  border: 0.05rem solid var(--border-color);
  cursor: pointer;
}

.sidebar-header:hover {
  background-color: var(--hover-color);
}

.sidebar-history {
  height: 100%;
  overflow: auto;
}

.sidebar-history::-webkit-scrollbar {
  width: 8px;
}

.sidebar-history::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.sidebar-history::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

.sidebar-history p {
  background-color: var(--primary-color);
  padding: 0.4rem;
  color: var(--text-color);
  font-size: 0.8rem;
  font-weight: 600;
  z-index: 1;
}

.Conversation_Unique_Title {
  padding: 0.4rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Conversation_Unique_Title.active {
  background-color: var(--secondary-color);
}

.sidebar-info {
  width: 100%;
  border-top: 0.05rem solid var(--border-color);
  padding: 0.5rem;
}

.sidebar-info-upgrade,
.sidebar-info-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}

.sidebar li:hover,
.sidebar-info-upgrade:hover,
.sidebar-info-user:hover {
  background-color: var(--primary-color);
  border-radius: 0.3rem;
  cursor: pointer;
}

.sidebar-info-upgrade:hover,
.sidebar-info-user:hover {
  cursor: not-allowed;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.main h1 {
  font-size: 2rem;
}

.main-body {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}
.main-body::-webkit-scrollbar {
  width: 8px;
}

.main-body::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.main-body::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

.empty-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.empty-chat-container h3 {
  font-weight: 500;
}

.App_message_li {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  background-color: var(--secondary-color);
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
}

.App_message_li:nth-child(odd) {
  background-color: unset;
}

.App_message_li:nth-child(even) {
  background-color: var(--secondary-color);
}

.main-body .avatar {
  display: block;
  border-radius: 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
}

.role-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.main-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.App_scroll-to-bottom {
  padding: 5px;
  position: absolute;
  bottom: 80px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  color: var(--text-color);
  cursor: pointer;
}

.errorText {
  margin: 0 auto;
}

#errorTextHint {
  margin: 0 auto;
  opacity: 0.6;
}

.form-container {
  padding: 0.3rem 1.6rem;
  margin: 0 auto;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);
  box-shadow: rgb(0, 0, 0, 0.05) 0 3.3rem 3.4rem,
    rgb(0, 0, 0, 0.05) 0 -0.7rem 1.8rem, rgb(0, 0, 0, 0.05) 0 0.2rem 0.3rem,
    rgb(0, 0, 0, 0.05) 0 0.7rem 0.2rem, rgb(0, 0, 0, 0.05) 0 0.2rem 0.3rem;
}

.form-container input {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  padding-right: 1rem;
  background-color: var(--secondary-color);
  outline: none;
  border: none;
}

.form-container input::placeholder {
  color: var(--text-color);
}

.form-container svg {
  fill: var(--text-color);
}

.form-container svg:hover {
  fill: var(--accent-color);
}

@media screen and (max-width: 640px) {
  .main-body li {
    gap: 1rem;
  }

  .container {
    width: 100%;
    right: 0;
    bottom: 0;
  }

  .sidebar {
    width: 100%;
  }
}
